import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl';
import getContent from 'utils/getContentBySubstring';
import capitalizeFirstLetter from 'utils/capitalizeFirstLetter';
import { chapters } from 'config/chapters';
import Section from '../Section';
import { Heading, Subsection, Resource } from '../Section/styled';

const Resources = ({ intl: { messages } }) => (
  <Section title="intro_aboutResourcesTitle">
    {chapters.map(chapter => (
      <Subsection key={chapter.name} className="subsection">
        <Heading>
          <FormattedMessage id={`${chapter.name}_heading`} />
        </Heading>
        {getContent(
          messages,
          `intro_aboutRes${capitalizeFirstLetter(chapter.name)}`
        ).map(resource => (
          <Resource key={resource}>
            <FormattedHTMLMessage id={resource} />
          </Resource>
        ))}
      </Subsection>
    ))}
  </Section>
);

Resources.propTypes = {
  intl: PropTypes.shape({}).isRequired
}

export default injectIntl(Resources);
