import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from 'react-styled-flexboxgrid';
import { Wrapper, InfoBox, Title } from './styled';

const Section = ({ title, children, id }) => (
  <Wrapper>
    <Row center="xs">
      <Col xs={10} sm={10} md={8} lg={8}>
        {title ? (
          <Title id={id}>
            <FormattedMessage id={title} />
          </Title>
        ) : null}
      </Col>
    </Row>
    <Row center="xs">
      <Col xs={12} sm={10} md={8} lg={8}>
        <InfoBox>{children}</InfoBox>
      </Col>
    </Row>
  </Wrapper>
);

Section.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  id: PropTypes.string
};

Section.defaultProps = {
  title: null,
  children: null,
  id: null
};

export default Section;
