import React from 'react';
import { withIntl } from 'i18n';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import Layout from 'containers/Layout';
import { Outer, Inner } from 'containers/Wrapper';
import { Row, Col } from 'react-styled-flexboxgrid';
import Background from 'containers/Background';
import { H1 } from 'styles/typography';
import { Head, Menu } from 'components';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Section from './Section';
import Resources from './Resources';
import Credits from './Credits';
import { Wrapper, InnerWrapper, Main, HeaderWrapper } from './styled';

const About = () => (
  <Menu>
    <Background theme="offblue">
      <Head title="intro_aboutTitle" description="intro_aboutDescription" />
      <Layout>
        <Wrapper>
          <Outer>
            <HeaderWrapper>
              <Header color="white" />
            </HeaderWrapper>
            <Inner>
              <InnerWrapper>
                <Main>
                  <Row center="xs">
                    <Col xs={10} sm={10} md={8} lg={10}>
                      <H1><FormattedMessage id="interface_about"/></H1>
                    </Col>
                  </Row>
                  <Section>
                    <FormattedHTMLMessage id="intro_aboutIntro" />
                  </Section>
                  <Resources />
                  <Credits />
                </Main>
                <Footer />
              </InnerWrapper>
            </Inner>
          </Outer>
        </Wrapper>
      </Layout>
    </Background>
  </Menu>
);

export default withIntl(About);
