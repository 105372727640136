import styled from 'styled-components';
import { OuterWrapper, InfoBoxTitle } from 'components/InfoBox/styled';
import { h1Mixin, P } from 'styles/typography';
import { turquoise } from 'styles/variables';
import media from 'styles/media';

export const Heading = styled(InfoBoxTitle)``;

export const Resource = styled(P)``;

export const Wrapper = styled.section`
  width: 100%;
  height: auto;
  margin: 0 0 6rem 0;

  ${Heading} {
    text-align: center;
    margin: 0 0 2rem;
  }

  ${Resource} {
    margin: 0 0 0.5rem;
  }

  margin: 0 0 0.5rem 0;
`;

export const InfoBox = styled(OuterWrapper)`
  box-shadow: none !important;
  border: none;
  text-align: center;

  a {
    color: ${turquoise};
    font-weight: 500;
    text-decoration: underline;
  }

  ${media.xs`
    box-shadow: none;
  `}
`;

export const Subsection = styled.div`
  margin: 4rem 0;

  &:first-child,
  &:last-child {
    margin: 0;
  }
`;

export const Title = styled.h2`
  ${h1Mixin};
`;
