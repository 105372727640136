import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl';
import getContent from 'utils/getContentBySubstring';
import Section from '../Section';
import { Heading, Subsection, Resource } from '../Section/styled';

const Credits = ({ intl: { messages } }) => (
  <Section title="intro_aboutCreditsTitle" id="credits">
    {getContent(messages, 'intro_aboutCreditsH').map((heading, i) => (
      <Subsection key={heading} className="subsection">
        <Heading>
          <FormattedMessage id={heading} />
        </Heading>
        <Resource>
          <FormattedHTMLMessage id={`intro_aboutCreditsP${i + 1}`} />
        </Resource>
      </Subsection>
    ))}
  </Section>
);

Credits.propTypes = {
  intl: PropTypes.shape({}).isRequired
};

export default injectIntl(Credits);
