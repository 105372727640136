import styled from 'styled-components';
import { padding } from 'styles/variables';
import media from 'styles/media';

export const Wrapper = styled.div`
  height: auto;
  min-height: 100vh;
  width: 100%;
  padding: ${padding.top.lg}rem 0 0 0;

  ${media.sm`
    padding: ${padding.top.sm}rem 0 0 0;
  `};

  ${media.xs`
    padding: ${padding.top.xs}rem 0 0 0;
  `};
`;

export const InnerWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
`;

export const Main = styled.main`
  flex: 1 0 auto;
  width: 100%;
  margin: 5rem 0;

  ${media.xs`
    margin: 1rem 0;
  `};
`;

export const HeaderWrapper = styled.div`
  ${media.xs`
    display: none;
  `};
`;
